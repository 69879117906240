import { jsx as t, Fragment as d, jsxs as e } from "react/jsx-runtime";
import { useState as h } from "react";
import { Grid as r, CircularProgress as F } from "@mui/material";
import { Button as v } from "../atoms/Button/Button.js";
import "../../Chatbot.style-a4e603d0.js";
import "../../style.module-3bbdaa05.js";
import "../../DateTimeSelectorMenu-84657d02.js";
import "moment-timezone";
import { asset as f } from "../../assets/icons/index.js";
import { d as B } from "../../ArrowForward-8f6374ed.js";
import "../../runtime-6393d96e.js";
import "../../TableContext-c60e49ca.js";
import "../ReactTable/SearchFilter.js";
import "../ReactTable/TablePaginationActions.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../server.browser-ec1ee1ca.js";
import "../../transform-17824b7f.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import "../../ChatbotContext-74017a6b.js";
import "../atoms/FormControl/FormControl.js";
import "../atoms/FormControlLabel/FormControlLabel.js";
import "../../map-85279df3.js";
import "../atoms/Grid/Grid.js";
import "../atoms/Paper/Paper.js";
import "../atoms/Box/Box.js";
import "../atoms/Radio/Radio.js";
import "../atoms/RadioGroup/RadioGroup.js";
import { Input as x } from "../atoms/Input/Input.js";
import { Typography as n } from "../atoms/Typography/Typography.js";
import "../atoms/Stepper/Stepper.js";
import "../atoms/Step/Step.js";
import "../atoms/StepLabel/StepLabel.js";
const at = ({ errorsComponent: l, loading: p, onLogin: y, onNext: m, onForgotPassword: c, onLicenseAgreement: a, hidePassword: u = !0 }) => {
  const [s, w] = h(""), [g, C] = h("");
  function b(i) {
    i == null || i.preventDefault(), u ? m == null || m(s) : y(s, g);
  }
  return /* @__PURE__ */ t(d, { children: /* @__PURE__ */ t(r, { container: !0, sx: o.main, children: /* @__PURE__ */ e(r, { sx: o.loginContainer, children: [
    /* @__PURE__ */ e(r, { sx: o.topContainer, children: [
      /* @__PURE__ */ t(r, { sx: o.logo, children: /* @__PURE__ */ t("img", { src: f.loginPageLogo, width: "236" }) }),
      /* @__PURE__ */ e(r, { component: "form", onSubmit: b, sx: o.loginForm, children: [
        /* @__PURE__ */ t(n, { sx: o.welcomeBackText, color: "#878787", children: "Welcome back," }),
        /* @__PURE__ */ t(n, { sx: o.loginTitleText, color: "#141414", children: "Log in to your account" }),
        /* @__PURE__ */ t(r, { sx: o.error, children: l }),
        /* @__PURE__ */ t(
          x,
          {
            label: "Email",
            name: "email",
            required: !0,
            id: "username",
            placeholder: "Email",
            sx: o.inputEmail,
            type: "email",
            value: s,
            onChange: (i) => w(i.target.value),
            autoFocus: !0
          }
        ),
        !u && /* @__PURE__ */ t(
          x,
          {
            label: "Password",
            id: "password",
            name: "password",
            required: !0,
            type: "password",
            placeholder: "Password",
            autoFocus: !0,
            sx: o.inputPass,
            value: g,
            onChange: (i) => C(i.target.value)
          }
        ),
        /* @__PURE__ */ t(v, { type: "submit", sx: o.loginButton, disabled: p, children: p ? /* @__PURE__ */ e(d, { children: [
          p && /* @__PURE__ */ t(F, { size: 24 }),
          "  "
        ] }) : /* @__PURE__ */ e(d, { children: [
          /* @__PURE__ */ t(n, { type: "heading3", color: "#FFFFFF", sx: o.loginText, children: "Log In" }),
          /* @__PURE__ */ t(B, {})
        ] }) }),
        /* @__PURE__ */ e("div", { style: o.actions, children: [
          /* @__PURE__ */ t("div", { onClick: () => c == null ? void 0 : c(), children: /* @__PURE__ */ t(n, { type: "heading3", color: (i) => i.palette.primary.main, sx: { ...o.pointer, fontWeight: 500 }, children: "Forgot/Reset Password" }) }),
          !!a && /* @__PURE__ */ t("div", { onClick: () => a == null ? void 0 : a(), children: /* @__PURE__ */ t(n, { type: "heading3", color: (i) => i.palette.primary.main, sx: { ...o.pointer, fontWeight: 500 }, children: "Lisence Agreement" }) })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ e(r, { sx: o.noAccount, children: [
      /* @__PURE__ */ t(n, { type: "heading4", color: "#141414", sx: { fontWeight: 400 }, children: "Don’t have an account?" }),
      /* @__PURE__ */ t("div", { onClick: () => {
        window.open("mailto:support@highway9.com");
      }, children: /* @__PURE__ */ t(n, { type: "heading3", color: (i) => i.palette.primary.main, sx: o.pointer, children: "Contact Support" }) })
    ] })
  ] }) }) });
}, o = {
  main: (l) => ({
    width: "100vw",
    height: "100vh",
    overflow: "none",
    backgroundColor: l.palette.primary.main,
    backgroundImage: `url(${f.loginPageBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }),
  logo: {
    textAlign: "center",
    marginBottom: "40px"
  },
  loginContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    width: "100%",
    mx: "8px",
    maxHeight: "95vh",
    maxWidth: "500px"
  },
  topContainer: {
    padding: "3rem 3.5rem 2rem"
  },
  loginForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    gap: "8px"
  },
  noAccount: {
    borderTop: "1px solid #EFEFEF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: "32px",
    gap: "8px"
  },
  pointer: {
    cursor: "pointer"
  },
  loginButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "100%",
    marginBottom: "16px",
    "&::before ": {
      content: "''",
      width: 0
    }
  },
  welcomeBackText: {
    fontSize: "1rem",
    marginBottom: "8px"
  },
  loginTitleText: {
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "8px"
  },
  loginText: {
    textAlign: "center"
  },
  inputEmail: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  inputPass: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  error: {
    marginBottom: "1rem",
    marginTop: "0.5rem",
    width: "100%",
    "& .MuiAlert-root": {
      borderRadius: "8px"
    }
  }
};
export {
  at as default
};
